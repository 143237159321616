class ImageLightbox {

    constructor(div) {
        this.el = {
            div,
            divContent: $(cE('div')),
            imgPage: div.find('.gallery-item img'),
            imgThumbs: div.find('.gallery-thumbs img'),
            lightbox: $(cE('div')),
            closer: $(cE('div')),
            arrowLeft: $(cE('div')),
            arrowRight: $(cE('div')),
            imgLightbox: $(cE('img'))
        };

        this.el.closer
            .addClass('close')
            .text('close');
        this.el.arrowLeft
            .addClass('arrow-left');
        this.el.arrowRight
            .addClass('arrow-right');
        this.el.divContent
            .append(this.el.closer)
            .append(this.el.arrowLeft)
            .append(this.el.arrowRight)
            .append(this.el.imgLightbox);
        this.el.lightbox
            .addClass('lightbox image-lightbox');
        this.el.lightbox.append(this.el.divContent);
        el.main.append(this.el.lightbox);

        this.el.lightbox.mousedown(() => false); // prevent accidental highlight
        this.el.imgPage.click(this._openLightbox.bind(this));
        this.el.closer.click(this._closeLightbox.bind(this));
        this.el.arrowLeft.click(this._prev.bind(this));
        this.el.arrowRight.click(this._next.bind(this));
    }

    _openLightbox(event) {
        this.el.imgLightbox.attr({ 'src': event.target.src });
        this.el.lightbox.css({ 'display': 'block', 'opacity': '1' });
    }

    _closeLightbox() {
        this.el.lightbox
            .css({ 'opacity': '0' })
            .delay(200)
            .css({ 'display': 'none' });
    }

    _next(event) {
        const currentIndex = this.el.imgThumbs
            .filter((index, thumb) => {
                return thumb.src === this.el.imgLightbox.attr('src');
            })
            .index();
        const newSrc = this.el.imgThumbs.eq(currentIndex + 1).attr('src');
        this.el.imgLightbox.attr({ 'src': newSrc });
    }

    _prev(event) {
        let currentIndex = this.el.imgThumbs
            .filter((index, thumb) => {
                return thumb.src === this.el.imgLightbox.attr('src');
            })
            .index();
        currentIndex = Math.max(currentIndex, 1); // prevent looping
        const newSrc = this.el.imgThumbs.eq(currentIndex - 1).attr('src');
        this.el.imgLightbox.attr({ 'src': newSrc });
    }

}
