/* CONSTANTS */
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep',
    'Oct', 'Nov', 'Dec'];

/* UTILS */

function createBasicSection() {
    const section = $(cE('section'));
    const divDom = cE('div');
    section.append(divDom);
    const div = $(divDom);
    return {section, div};
}

/* CREATORS */

/**
 * Creates a gallery section to be inserted in between the `<header>` and
 * `<footer>` of the page.
 *
 * @param {object} data - The parsed ACF API data for a gallery section.
 */
function createGallerySection(data) {
    let {section, div} = createBasicSection();
    section.addClass('full-width');
    div.addClass('gallery').addClass('shaded');
    if (data['static-text']) {
        div.addClass('static-text');
    }
    if (data['logo-badge']) {
        section = section.add($(cE('div')).addClass('logo-badge'));
    }

    /* ACF is set to require at least one slide, but we'll double-check. */
    if (data.slides) {
        const divImages = $(cE('div')).addClass('images');
        const divTexts = $(cE('div')).addClass('texts');
        const divIndicators = $(cE('div')).addClass('indicators');
        let firstSlide = true;

        data.slides.forEach((slide) => {
            const divImage = $(cE('div'));
            divImage.css('background-image',
                         `url(${slide['background-image']})`);
            divImage.addClass(`align-${slide['background-image-align'].toLowerCase()}`);
            divImages.append(divImage);

            if (firstSlide || !data['static-text']) {
                firstSlide = false;
                const divText = $(cE('div')).addClass('slide');
                const h1 = $(cE('h1')).text(slide.title);
                const p = $(cE('p')).text(slide.description);
                divText.append(h1).append(p);
                divTexts.append(divText);
            }

            divIndicators.append(cE('div'));
        });

        div.append(divImages);
        div.append(divTexts);
        div.append(divIndicators);
    }

    galleries.push(new Gallery(div));
    return section;
}

/**
 * Creates a text-over-image section to be inserted in between the `<header>`
 * and `<footer>` of the page.
 *
 * @param {object} data - The parsed ACF API data for a text-over-image section.
 */
function createTextOverImageSection(data) {
    let {section, div} = createBasicSection();
    section
        .css('background-image', `url('${data['background-image']}')`)
        .addClass('shaded');
    div.addClass('slide');
    div.append($(cE('h1'))
        .text(data.title));
    if (data['darker-background']) {
        section.addClass('dark-shaded');
    }
    if (data['logo-badge']) {
        section = section.add($(cE('div')).addClass('logo-badge'));
    }
    if (data.description) {
        if (data.description.length > 300) {
            section.addClass('paragraphs');
            if (data.description.length > 500) {
                section.addClass('paragraphser');
            }
        }
        // no idea why wp is spitting out windows/crlf newlines
        let paragraphs = data.description.trim().split('\r\n\r\n');
        paragraphs.forEach((paragraph) => {
            div.append($(cE('p')).text(paragraph));
        });
    }
    return section;
}

/** EXTENDED SECTIONS */

/**
 * Creates a text section to be inserted in between the `<header>` and
 * `<footer>` of the page.
 *
 * @param {object} data - The parsed ACF API data for a text section.
 */
function createTextSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended');
    div.html(data.content);
    return section;
}

function createTwo_columnsSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended two-cols');
    const div1 = $(cE('div')).addClass('left-col');
    const div2 = $(cE('div')).addClass('right-col');
    const clear = $(cE('div')).addClass('clear');
    div1.html(data.column_1);
    div2.html(data.column_2);
    div.append(div1);
    div.append(div2);
    div.append(clear);
    return section;
}

/**
 * Creates an associate logos section to be inserted in between the `<header>`
 * and `<footer>` of the page.
 *
 * @param {object} data - The parsed ACF API data for a associate logos section.
 */
function createAssociateLogosSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended').addClass('associate-logos');
    const divImages = $(cE('div')).addClass('flex');

    // arrow controls
    const elems = [];
    let index = 0;
    const goPrev = () => {
        elems[(index + 4) % elems.length].addClass('hidden');
        index--;
        if (index < 0) {
            index += elems.length;
        }
        let revealIndex = (index) % elems.length;
        if (revealIndex < 0) {
            revealIndex += elems.length;
        }
        elems[index].removeClass('hidden');
        divImages.prepend(elems[index]);
    }
    const goNext = () => {
        elems[index].addClass('hidden');
        index++;
        index %= elems.length;
        const revealIndex = (index + 4) % elems.length;
        elems[revealIndex].removeClass('hidden');
        divImages.append(elems[revealIndex]);
    }

    if (data.title) {
        div.append($(cE('h1')).text(data.title));
    }
    if (data.description) {
        div.append($(cE('p')).text(data.description));
    }
    if (data['associate-logos']) {
        if (data['wide-images']) {
            divImages.addClass('wide-images');
        }
        data['associate-logos'].forEach((associateLogo, index) => {
            const img = $(cE('img')).attr({ 'src': associateLogo.logo });
            let elem;
            if (associateLogo.link) {
                elem = $(cE('a'))
                    .attr({ 'href': associateLogo.link })
                    .append(img);
            } else {
                elem = img;
            }
            if (index > 4) {
                elem.addClass('hidden');
            }
            elems.push(elem);
            divImages.append(elem);
        });
        if (data['associate-logos'].length > 5) {
            div.addClass('interactive');
            setInterval(goNext, 3000);
            divImages
                .append($(cE('div')).addClass('arrow-left').click(goPrev))
                .append($(cE('div')).addClass('arrow-right').click(goNext));
        }
        div.append(divImages);
    }
    return section;
}

/**
 * Creates a circle links section to be inserted in between the `<header>` and
 * `<footer>` of the page.
 *
 * @param {object} data - The parsed ACF API data for a circle links section.
 */
function createCircleLinksSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended').addClass('circle-links');
    if (data.title) {
        div.append($(cE('h1')).text(data.title));
    }
    data['circle-links'].forEach((circleLink) => {
        const img = cE('img');
        img.src = circleLink['background-image'];
        const span = cE('span');
        $(span).text(circleLink.text);
        div.append($(cE('a'))
            .addClass('shaded')
            .attr({ 'href': normaliseUrl(circleLink.page) })
            .append(img)
            .append(span)
        );
    });
    return section;
}

function createBrandsSection(data, entries) {
    const entry = entries.filter((v) => v["id"] == data)[0];
    const {section, div} = createBasicSection();
    section
        .css('background-image', `url('${entry.acf.background}')`)
        .addClass('paragraphs paragraphser shaded dark-shaded');
    div.addClass('slide');

    const divLogo = $(cE('div')).addClass('brand-logo');
    divLogo.append($(cE('img'))
        .attr({
            'src': entry.acf.logo,
            'width': '170'
        }));
    div.append(divLogo);
    const divText = $(cE('div')).addClass('brand-text');
    const h1 = $(cE('h1')).text(entry.title.rendered);
    const p = $(cE('div')).html(entry.acf.summary).addClass('brand-summary');
    divText.append(h1).append(p);

    if (entry.acf.link) {
        const a = $(cE('a')).addClass('btn-primary')
        if (entry.acf.link.substr(entry.acf.link.length - 16) == '/about/our-group') {
            a.text('Other Parkwood Companies')
                .attr({ 'href': '/about/our-group' });
        } else {
            a.text('Visit Website')
                .attr({ 'href': entry.acf.link });
        }
        divText.append(a);
    }

    div.append(divText);
    return section;
}

function createStaffSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended staff');
    if (data) {
        data.reverse();
        div.append($(cE('h2')).text('Technical Leaders'));
        data.forEach((member) => {
            const a = $(cE('a'))
                .attr({ 'data-routing-ignore': '' })
                .append($(cE('div'))
                    .addClass('img')
                    .css({ 'background-image': `url(${member.acf.image})` }));
                    //.append($(cE('img')).attr({ 'src': member.acf.image })));

            const span = $(cE('span')).text(member.title.rendered);
            a.append(span);

            div.append(a);
        });
        new LightboxSet(div, data);
    }
    return section;
}

function createVacancySection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended');
    div.append($(cE('h3')).text(data.post_title));
    div.append($(cE('div')).html(data.acf.description));
    return section;
}

function createProjectsSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('projects extended');
    data.acf.projects.forEach((entry) => {
        if (entry.acf.project_type === data.post_name) {
            const project = $(cE('div')).addClass('project-item');
            const link = $(cE('a'))
                //.html(`${entry.acf.project_name},<br>${entry.acf.location}`)
                .html(`${entry.acf.headline}`)
                .attr('href', `/project/${entry.slug}/`);
            const span = $(cE('span'));
            link.prepend(span);
            if (entry.acf.thumbnail) {
                    span
                        .css({
                            'background-image': `url(${entry.acf.thumbnail.url})`
                        })
                        .append($(cE('img')).attr({ src: '/assets/square-filler.svg' }));
            }
            else {
                if (entry.acf.gallery) {
                    span
                        .css({
                            'background-image': `url(${entry.acf.gallery[0].url})`
                        })
                        .append($(cE('img')).attr({ src: '/assets/square-filler.svg' }));
                }
            }
            project.append(link);
            div.append(project);
        }
    });
    const clear = $(cE('div')).addClass('clear');
    div.append(clear);
    return section;
}

function createProjectSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended sidebarred project');

    section.addClass(`type-${data.acf.project_type}`);
    el.body.addClass('entry-single');

    const ul = $(cE('ul'));
    fetch(`${apiPrefix}/wp/v2/project?per_page=100`)
        .then((data) => data.json())
        .then((json) => {
            json.forEach((project) => {
                if (project.acf.project_type === data.acf.project_type) {
                    const link = project.link
                        .replace(/https?:\/\/[A-z0-9-.]+/, '');
                    const li = $(cE('li'));
                    li.append($(cE('a'))
                        .html(project.title.rendered)
                        .attr({ 'href': normaliseUrl(link) })
                        .click(anchorClick));
                    ul.append(li);
                }
            });
        });
    const aside = $(cE('aside'))
        .append($(cE('h4')).text(`${data.acf.project_type} projects`))
        .append(ul);
    div.before(aside);

    const project = $(cE('div')).addClass('project-details');
    const projectGallery = $(cE('div')).addClass('project-gallery');
    const projectSummary = $(cE('div')).addClass('project-title');
    const projectDetail = $(cE('div')).addClass('project-detail');
    const projectGalleryMain = $(cE('div')).addClass('gallery-item');
    const projectGalleryThumbs = $(cE('div')).addClass('gallery-thumbs');
    if (data.acf.gallery !== undefined) {
        projectGalleryMain.append($(cE('img')).attr('src', data.acf.gallery[0].url));
        $.each(data.acf.gallery, function(g){
            projectGalleryThumbs.append($(cE('img')).attr('src', data.acf.gallery[g].url));
        });
        $(document).on('click touch', '.gallery-thumbs img', function(t){
            $('.gallery-item img').attr('src', t.currentTarget.src);
        })
    }
    projectGallery.append(projectGalleryMain);
    projectGallery.append(projectGalleryThumbs);
    new ImageLightbox(projectGallery);

    projectSummary.append($(cE('h3'))
        .html(`${data.acf.title}`));
        //.html(data.acf.project_name + ',<br>' + data.acf.location));
    projectSummary.append($(cE('h3'))
        .text(data.acf.client));
    projectDetail.append($(cE('div'))
        .addClass('project-summary')
        .html('<h3>Summary</h3>' + data.acf.summary));
    projectDetail.append($(cE('div'))
        .html('<h3>Description</h3>' + data.acf.description));
    project.append(projectGallery);
    project.append(projectSummary);
    project.append(projectDetail);
    div.append(project);

    setTimeout(() => {
        aside.css({ 'min-height': div[0].clientHeight }); // I cry
    }, 200);
    return section;
}

function createNewsSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended sidebarred article');
    $('body').addClass('entry-single');

    const maxSidebarPosts = 10;
    const ul = $(cE('ul'));
    fetch(`${apiPrefix}/wp/v2/posts?per_page=${maxSidebarPosts + 1}`)
        .then((res) => res.json())
        .then((json) => {
            let showMoreLink;
            if (json.length > maxSidebarPosts) {
                json.pop();
                showMoreLink = true;
            }
            json.forEach((article) => {
                //const link = article.link
                //    .replace(/https?:\/\/[A-z0-9-.]+/, '/article');
                const link = `/article/${article.slug}`;
                const a = $(cE('a'))
                    .html(article.title.rendered)
                    .attr({ 'href': normaliseUrl(link), 'data-routing-ignore': '' });
                const li = $(cE('li'))
                    .append(a);
                ul.append(li);
            });
            if (showMoreLink) {
                aside.append($(cE('a'))
                    .text('Post Archive')
                    .addClass('btn-primary')
                    .attr({ 'href': '/news/archive/' })
                );
            }
        });

    const aside = $(cE('aside'))
        .append($(cE('h4')).text('headlines'))
        .append(ul);
    div.before(aside);

    const article = $(cE('div')).addClass('articles-details');
    article.append($(cE('h2')).html(data.title.rendered));
    const publishDate = new Date(Date.parse(data.date));
    const publishDateStr = `${months[publishDate.getMonth()]} ${publishDate.getFullYear()}`;
    const authorStr = data.acf.author ? `, ${data.acf.author}` : '';
    article.append($(cE('h6')).text(`${publishDateStr}${authorStr}`));
    article.append($(cE('article')).html(data.content.rendered));

    const aShare = $(cE('a')).addClass('share-btn')
        .html('<svg viewBox="0 0 20 20"><path d="M3.13,6.88a3.11,3.11,0,0,1,2,.73l8.62-4.31V3.13a3.12,3.12,0,1,1,1.12,2.4L6.24,9.84v.33l8.62,4.31a3.12,3.12,0,1,1-1.12,2.4v-.16L5.13,12.39a3.12,3.12,0,1,1-2-5.52Z" /></svg>share<ul class="share-buttons"><li><a href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.epd-parkwood.co.uk&t=" title="Share on Facebook" target="_blank" onclick="window.open(\'https://www.facebook.com/sharer/sharer.php?u=\' + encodeURIComponent(document.URL) + \'&t=\' + encodeURIComponent(document.URL)); return false;"><img alt="Share on Facebook" src="/assets/icon/facebook.png"></a></li><li><a href="https://twitter.com/intent/tweet?source=http%3A%2F%2Fwww.epd-parkwood.co.uk&text=:%20http%3A%2F%2Fwww.epd-parkwood.co.uk" target="_blank" title="Tweet" onclick="window.open(\'https://twitter.com/intent/tweet?text=\' + encodeURIComponent(document.title) + \':%20\'  + encodeURIComponent(document.URL)); return false;"><img alt="Tweet" src="/assets/icon/twitter.png"></a></li><li><a href="https://plus.google.com/share?url=http%3A%2F%2Fwww.epd-parkwood.co.uk" target="_blank" title="Share on Google+" onclick="window.open(\'https://plus.google.com/share?url=\' + encodeURIComponent(document.URL)); return false;"><img alt="Share on Google+" src="/assets/icon/google-plus.png"></a></li><li><a href="https://www.linkedin.com/shareArticle?url=http%3A%2F%2Fepd-parkwood.co.uk&mini=true" target="_blank" title="Share on LinkedIn" onclick="window.open(\'https://www.linkedin.com/shareArticle?url=\' + encodeURIComponent(document.URL) + \'&mini=true\'); return false;"><img alt="Share on LinkedIn" src="/assets/icon/linkedin.png"></a></li></ul>');

    const aEmailBody = `${data.title.rendered}: ${frontendPrefix}article/${data.slug}`;
    const aEmail = $(cE('a'))
        .attr({ 'href': `mailto:?subject=EPD Article&body=${aEmailBody}` })
        .html('<svg viewBox="0 0 20 20"><path d="M0 5.2V20h20V5.2zm17.7 1.7L10 12.8 2.3 6.9zm-16 1.7l4.1 3.2-4.1 4.1zm0 9.7l5.5-5.5 2.8 2.1 2.8-2.1 5.5 5.5zm16.6-2.4l-4.2-4.2 4.2-3.2z"></path></svg>email');
    const aPrint = $(cE('a'))
        .html('<svg viewBox="0 0 20 20"><rect x="6" y="3" width="8" height="1"/><rect x="6" y="5" width="8" height="1"/><rect x="6" y="7" width="8" height="1"/><rect x="6" y="9" width="8" height="1"/><path d="M18,6H17V0H3V6H2A2,2,0,0,0,0,8V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8A2,2,0,0,0,18,6ZM4.5,1.5h11v10H4.5ZM18.5,18a.5.5,0,0,1-.5.5H2a.5.5,0,0,1-.5-.5V8A.5.5,0,0,1,2,7.5H3V13H17V7.5h1a.5.5,0,0,1,.5.5Z"/><rect x="3" y="14" width="2" height="1"/></svg>print')
        .click(() => { window.print() });
    article.append($(cE('div')).addClass('share')
        .append(aShare).append(aEmail).append(aPrint));

    aShare.click(() => {
        $('.share-buttons').fadeToggle(300);
    });
    div.append(article);
    setTimeout(() => {
        aside.css({ 'min-height': div[0].clientHeight }); // I cry
    }, 300);
    return section;
}

function createPostArchiveSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended');
    const table = $(cE('table'));
    const tbody = table.append($(cE('tbody')));
    fetch(`${apiPrefix}/wp/v2/posts`)
        .then((res) => res.json())
        .then((posts) => {
            posts.forEach((post) => {
                const publishDate = new Date(Date.parse(post.date));
                const publishDateStr = months[publishDate.getMonth()] + ' ' +
                    publishDate.getFullYear();
                const a = $(cE('a'))
                    .html(post.title.rendered)
                    .attr({ 'href': `/article/${post.slug}/` });
                const tr = $(cE('tr'))
                    .append($(cE('td')).text(publishDateStr))
                    .append($(cE('td')).text(post.acf.author))
                    .append($(cE('td')).append(a));
                tbody.append(tr);
            });
        });
    div.append(table);
    return section;
}

function createServicesSection(data) {
    const {section, div} = createBasicSection();
    //section.addClass('service '+data.post_name+'-circles');
    section.addClass(data.post_name + '-circles');
    section.addClass('extended');
    $('body').addClass('entry-single');

    const circles = $(cE('div')).addClass('section-circles');
    if (data.acf.services) {
        $.each(data.acf.services, (s) => {
            circles.append($(cE('a'))
                .attr({
                    'data-content': data.acf.services[s].post_content,
                    'data-routing-ignore': ''
                })
                .addClass('shaded')
                .html(`<img src="/assets/circles.png"><span>${data.acf.services[s].post_title}</span>`));
        });
    }
    div.append(circles);

    const copy = $(cE('div')).addClass('service-copy');
    $(document).on('click touch','.section-circles a', function(event){
        event.preventDefault();
        $('.section-circles a.active').removeClass('active');
        $(this).addClass('active');
        copy.html(`<h2>${event.currentTarget.text}</h2>${$(this).attr('data-content')}`);
    });
    div.append(copy);

    return section;
}

function createServiceSection(data, entries) {
    const {section, div} = createBasicSection();
    section.addClass('service');
    $('body').addClass('entry-single');
    const service = $(cE('div')).addClass('service-details');
    service.append($(cE('h2')).html(data.post_title));
    service.append($(cE('div')).html(data.post_content));
    div.append(service);
    return section;
}

function createSitemapSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('extended').addClass('sitemap');
    if (data) {
        if (data.title) {
            div.append($(cE('h1')).text(data.title));
        }
        const ul = $(cE('ul'));
        fetch(`${apiPrefix}/wp-api-menus/v2/menu-locations/primary`)
            .then((res) => res.json())
            .then((headerData) => {
                headerData.forEach((item) => {
                    let li = $(cE('li'));
                    let a = $(cE('a'));
                    a.text(item.title);
                    a.attr({ 'href': normaliseUrl(item.url) });
                    li.append(a);

                    if (item.children) {
                        let subUl = $(cE('ul'));
                        item.children.forEach((subItem) => {
                            let subLi = $(cE('li'));
                            let subA = $(cE('a'));
                            subA.text(subItem.title);
                            subA.attr({ 'href': normaliseUrl(subItem.url) });
                            subLi.append(subA);
                            subUl.append(subLi);
                        });
                        li.append(subUl);
                    }

                    ul.append(li);
                });
                div.append(ul);
            });
    }
    return section;
}

function createGoogle_mapSection(data) {
    const {section, div} = createBasicSection();
    section.addClass('google-map full-width');
    const canvas = $(cE('div')).addClass('map-canvas');
    div.append(canvas);

    return section;
}
