/** Class representing and handling a form. */
class Form {

    /**
     * Create a form.
     *
     * @param {object} div - jQuery object of the relevant `form` element.
     */
    constructor(form) {
        this.el = {
            form,
            submit: form.find('[type="submit"]'),
            fields: form.find('input, textarea').not('[type="submit"]')
        };
        this.el.submit.click(this.submit.bind(this));
        //this.el.fields.blur(this.validate.bind(this));
    }

    validate() {
        for (let i = 0; i < this.el.fields.length; i++) {
            const field = $(this.el.fields[i]);
            field.removeClass('error');
            if (field.attr('required') === 'required' &&
                field.val().length < 1 ) {
                field.addClass('error');
                return false;
            }
        }
        return true;
    }

    submit() {
        if (this.validate()) {
            fetch(apiFormEndpoint, {
                method: 'POST',
                mode: 'no-cors',
                body: new FormData(this.el.form[0])
            })
                .then((res) => {
                    this.el.form.html('<strong>Thank-you for submitting the form</strong>');
                });
            return false;
        }
    }

}
