function initRouting() {
    $('a[target!="_blank"]').click(anchorClick);
}
whenDone(initRouting);

/**
 * Handler for `<a>` click events. It checks if the element targets an internal
 * or external address. Internal links are handled with AJAX instead, whereas
 * external links are left unaltered.
 *
 * @param {object} event - Event object passed by jQuery.
 */
function anchorClick(event) {
    // `this' is the clicked anchor
    // ignore external links
    if (!isPreIE10 && getOrigin(this) === getOrigin(location) &&
        !event.ctrlKey && !event.metaKey) { // allow "open in new tab"
        event.preventDefault();
        if (typeof this.pathname === 'string' && this.href !== location.href) {
            $(this)
                .add($(this.parentNode.parentNode).siblings('a'))
                .addClass('selected');
            loadUrl(this.pathname);
            //ga('send', 'pageview');
        }
    }
}

/**
 * Attatches routing click event handlers to anchors found within the supplied
 * jQuery object.
 *
 * @param {object} parent - jQuery element object to search in.
 */
function attachAnchorClick(parent) {
    parent.find('a[target!="_blank"]').click(anchorClick);
}

/**
 * Handler for history state events.
 *
 * @param {object} event - Event object passed by caller.
 */
function windowPopstate(event) {
    fillSections(event.state);
}
window.addEventListener('popstate', windowPopstate);
