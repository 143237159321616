const frontendDomain = 'www.epd-parkwood.co.uk';
const frontendPrefix = `https://${frontendDomain}`;
const apiDomain = 'www.epd-parkwood.co.uk';
const apiRoot = `https://${apiDomain}/api`;
const apiFormEndpoint = `${frontendPrefix}/`;
const apiPrefix = `${apiRoot}/wp-json`;
const siteName = 'EPD';

const el = {};
el.window = $(window);
el.body = $(document.body);
el.main = $('#main');
el.header = $('header');
el.menuLabel = $('#menu-label');
el.logo = $('#logo');
el.footer = $('footer');
el.loader = $('#loader');

const isPreIE10 = $(document.documentElement).hasClass('pre-ie10');
const rootLinks = {}
const galleries = [];
const doneQueue = [];
galleries.clear = function(){
    this.forEach((gallery) => gallery.stopAuto());
    this.length = 0;
}

/**
 * (_Closure_) Carries out a few tasks after it's been called a number of times.
 * This is essentially to do something once all async loading activities
 * complete. The initial value of `count` is the number of times the function
 * must be called for the tasks to execute.
 */
const done = (() => {
    let count = 3;
    return function done() {
        count--;
        if (count == 0) {
            doneQueue.forEach((callback) => callback());
            el.body.removeClass('loading');
        }
    }
})();

/** 
 * Registers a callback in the queue of tasks to be carried out once the page
 * data has loaded and rendered.
 *
 * @param {function} callback - A function to register in the queue.
 */
function whenDone(callback) {
    if (typeof callback === 'function') {
        doneQueue.push(callback);
    }
}

/**
 * Normalises a given URL if it's detected as being internal. The domain of the
 * API server is removed and the URL made relative.
 *
 * @param {string} url - A URL to normalise.
 */
function normaliseUrl(url) {
    return url
        .replace(apiRoot, '')
        .replace('/api', '');
}

/**
 * Appends the site name to a string, or returns just the site name if no string
 * is given.
 *
 * @param {object} loc - Object with `protocol` and `host` properties
 * (e.g., `window.location`).
 */
function getOrigin(loc) {
    if (loc && loc.protocol && loc.host) {
        return `${loc.protocol}//${loc.host}`
    } else {
        return '';
    }
}


/**
 * Appends the site name to a string, or returns just the site name if no string
 * is given.
 *
 * @param {string} title - Page title or other string to wrap.
 */
function wrapTitle(title) {
    if (title && title.length > 0) {
        return `${title} - ${siteName}`;
    } else {
        return siteName;
    }
}

/**
 * Converts `kebab-case` strings to `CamelCase` strings.
 *
 * @param {string} str - `kebab-case` string to be converted.
 */
function kebabToCamel(str) {
    str = str.toLowerCase();
    let words = str.split('-');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join('');
}

/**
 * Alias for `document.createElement()`. For parameters see the JavaScript
 * documentation, see [here]
 * (https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement).
 */
function cE() {
    return document.createElement.apply(document, arguments);
}

// only what we need of jQuery easings
$.extend($.easing, {
    easeInQuad: function (x, t, b, c, d) {
        return c*(t/=d)*t + b;
    },
    easeOutQuint: function (x, t, b, c, d) {
        return c*((t=t/d-1)*t*t*t*t + 1) + b;
    }
});
