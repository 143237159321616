class LightboxSet {

    constructor(div, data) {
        this.bios = data;
        this.el = {
            div,
            openers: div.children('a'),
            closer: $(cE('div')),
            wrapper: $(cE('div')),
            content: $(cE('div')),
            lightbox: $(cE('div')),
            sidebar: $(cE('aside')),
            image: $(cE('div'))
        };

        this.el.sidebar
            .append(this.el.image);
        this.el.content
            .addClass('content');
        this.el.wrapper
            .append($(cE('div')).addClass('logo-badge'))
            .append(this.el.closer)
            .append($(cE('section')).addClass('line'))
            .append(this.el.sidebar)
            .append(this.el.content)
            .append($(cE('section')).addClass('line'));
        this.el.lightbox
            .addClass('lightbox')
            .append(this.el.wrapper);
        el.main.append(this.el.lightbox);

        this.el.closer.text('close').addClass('close');

        el.window.keyup((event) => {
            if (event.which === 27) {
                this._closeLightbox();
            }
        });
        this.el.openers.click(this._openLightbox.bind(this));
        this.el.closer.click(this._closeLightbox.bind(this));
    }

    _loadToLightbox(bio) {
        if (bio) {
            this.el.content.children().not(this.el.sidebar).remove();

            const p = $(cE('p'));
            if (bio.acf.linkedin) {
                p.append($(cE('a'))
                    .addClass('icon-linkedin')
                    .attr({ 'href': bio.acf.linkedin })
                    .text('LinkedIn'));
            }
            if (bio.acf.linkedin && bio.acf.email) {
                p.append(document.createTextNode(' • '));
            }
            if (bio.acf.email) {
                p.append($(cE('a'))
                    .addClass('icon-email')
                    .attr({ 'href': `mailto:${bio.acf.email}` })
                    .text(bio.acf.email));
            }

            this.el.image.css({ 'background-image': `url(${bio.acf.image})` });
            this.el.content
                .append($(cE('h2')).text(bio.title.rendered))
                .append($(cE('h4')).text(bio.acf.position))
                .append($(bio.acf.overview))
                .append(p);
        }
    }

    _openLightbox(event) {
        event.preventDefault();
        event.returnValue = false;

        let target;
        if (event.target.tagName === 'DIV' || event.target.tagName === 'SPAN') {
            target = event.target.parentNode;
        } else {
            target = event.target;
        }

        const index = this.el.openers.index(target);
        const bio = this.bios[index];
        $(target).addClass('selected');
        this._loadToLightbox(bio);
        this.el.lightbox.css({ 'display': 'block', 'opacity': '1' });
    }

    _closeLightbox(event) {
        event.preventDefault();
        event.returnValue = false;

        this.el.openers.removeClass('selected');
        this.el.lightbox
            .css({ 'opacity': '0' })
            .delay(200)
            .css({ 'display': 'none' });
    }

}
