/** Class representing and controlling a gallery section. */
class Gallery {

    /**
     * Create a gallery.
     *
     * @param {object} div - jQuery object of the relevant `div.gallery`
     * element.
     */
    constructor(div) {
        this.el = {
            div,
            imagesDiv: div.children('.images'),
            images: div.find('.images div'),
            indicators: div.find('.indicators div'),
            texts: div.find('.texts div')
        };
        this.el.images.slice(1).fadeOut(0);
        this.el.indicators.eq(0).addClass('selected');
        this.el.indicators.click(this._indicatorClick.bind(this))
        this.el.texts.slice(1).css({left: '-100%'});

        this.staticText = div.hasClass('static-text');
        this.length = this.el.images.length;
        this._current = 0;

        this.setAuto();
    }

    /**
     * Navigate to the next slide.
     */
    next() {
        let newCurrent = this._current + 1;
        if (newCurrent == this.length) {
            newCurrent = 0;
        }
        return this.goTo(newCurrent);
    }

    /**
     * Navigate to the previous slide.
     */
    previous() {
        let newCurrent = this._current - 1;
        if (newCurrent == -1) {
            newCurrent = this.length - 1;
        }
        return this.goTo(newCurrent);
    }

    /**
     * Navigate to a given slide.
     *
     * @param {number} newCurrent - ID (zero-based index) of new slide to
     * navigate to.
     */
    goTo(newCurrent) {
        if (this._disabled ||
            newCurrent === this._current ||
            !document.hasFocus()) {
            return;
        }
        this._disabled = true;

        let newImage = this.el.images.eq(newCurrent)
        this.el.imagesDiv.append(newImage);
        newImage.fadeIn(800, () => {
            this.el.images.eq(this._current).fadeOut(100, () => {
                this._current = newCurrent;
                this._disabled = false;
            });
        });

        this.el.indicators.eq(this._current).removeClass('selected');
        this.el.indicators.eq(newCurrent).addClass('selected');

        if (!this.staticText) {
            this.el.texts.eq(this._current)
                .animate({'left': '-100%'}, 800, 'easeInQuad');
            this.el.texts.eq(newCurrent)
                .css({left: '100%'})
                .delay(450)
                .animate({'left': '0'}, 800, 'easeOutQuint');
        }

        return newCurrent;
    }

    /**
     * Start automatic gallery movement if the gallery is not already moving.
     */
    setAuto() {
        if (typeof this._interval === 'number') {
            return;
        }
        this._interval = setInterval(() => {
            this.next();
        }, 7000);
    }

    /**
     * Stops automatic gallery movement if it's currently enabled.
     */
    stopAuto() {
        if (typeof this._interval === 'number') {
            clearInterval(this._interval);
        }
    }

    /**
     * Stops the automatic gallery movement, then starts it again, after an
     * optional delay.
     *
     * @param {number} [delay=0] - number of milliseconds to wait before
     * starting the interval again.
     */
    resetAuto(delay) {
        if (typeof delay === 'undefined') {
            var delay = 0;
        }
        clearInterval(this._interval);
        this._interval = undefined;
        this.setAuto();
    }

    /**
     * Click handler for the gallery indicators.
     */
    _indicatorClick(event) {
        const index = $(event.target).index();
        this.resetAuto(3500);
        this.goTo(index);
    }

}
