$(function(){
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB[0-9]+|Windows Phone/i)) {
        whenDone(() => {
            el.body.addClass('touch');
            el.menuLabel.click(toggleHeader);
            //$('header a').click(closeHeader);
            el.header.find('nav :not(#logo) a').click(toggleHeader);
        })
    }
});

function closeHeader() {
    el.header.removeClass('open');
}
function toggleHeader() {
    if (el.header.hasClass('open')) {
        closeHeader();
    } else {
        el.header.addClass('open');
    }
}
